<template>
  <div>
    <div>
      <el-button @click="handleAdd" size="small" type="primary">新增</el-button>
      <el-button @click="getShuttleBus" size="small">刷新</el-button>
    </div>
    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" class="margin-t-10"
      style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="班车类型" prop="name"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm title="确定删除吗？" @confirm="handleDel(row.id)">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="closeDialog"
      width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="班车类型" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getShuttleBusCategoriesAPI,
    saveShuttleBusCategoryAPI,
    delShuttleBusCategoryAPI,
  } from '@/api/shuttle-bus.js'

  export default {
    name: 'ShuttleBusCategory',
    data() {
      return {
        dialogTitle: '',
        showDialog: false,
        submitting: false,
        form: {
          name: '',
          remark: ''
        },
        rules: {
          name: [{
            required: true,
            message: '班车类型不能为空',
            trigger: 'blur'
          }],
        },

        tableData: [],
        tableLoading: false,
        curRowId: '',
      }
    },
    created() {
      this.getShuttleBus()
    },
    methods: {
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowId = row.id
        const copyRow = JSON.parse(JSON.stringify(row))
        // 回显
        for (let key in this.form) {
          this.form[key] = copyRow[key]
        }
      },
      handleDel(id) {
        delShuttleBusCategoryAPI({
          id,
        }).then(res => {
          this.$message.success(res.msg)
          this.getShuttleBus()
        })
      },
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          const copyForm = Object.assign({}, this.form)

          if (this.dialogTitle == '编辑') copyForm.id = this.curRowId

          saveShuttleBusCategoryAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getShuttleBus()
          }).finally(() => this.submitting = false)
        })
      },

      getShuttleBus() {
        this.tableLoading = true

        getShuttleBusCategoriesAPI().then(res => {
          this.tableData = res
          this.tableLoading = false
        })
      },
    }
  }
</script>

<style>
</style>